<template>
    <div class="SaleBill">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="所在城市" prop="cityId">
                        <el-select :ref="el=>refMap.set('cityId',el)" v-model="form.cityId" clearable placeholder="请选择所在城市" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cityId')}">
                            <el-option v-for="item in comboSelect({comboId:'cityId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="销售类型" prop="saleType">
                        <el-select :ref="el=>refMap.set('saleType',el)" v-model="form.saleType" clearable placeholder="请选择销售类型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'saleType')}">
                            <el-option v-for="item in comboSelect({comboId:'saleType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="客户" prop="customerId">
                        <el-select :ref="el=>refMap.set('customerId',el)" v-model="form.customerId" clearable placeholder="请选择客户" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'customerId')}">
                            <el-option v-for="item in comboSelect({comboId:'customerId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="项目名称" prop="name">
                        <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入项目名称" maxlength="50" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                   <div style="display: flex;justify-content: space-between">
                       <el-form-item>
                           <el-button type="info" :disabled="disabled && tjDisabled" @click="chooseCustomerQuotation">客户报价单</el-button>
                           <el-tag type="success" style="margin-left: 20px;cursor: pointer" v-if="customerQuotationInfo" @click="quotationDetail('customer')">{{customerQuotationInfo}}</el-tag>
                       </el-form-item>
                       <el-form-item label="折扣" prop="point" v-if="customerQuotationInfo">
                           <div style="display: flex;align-content: center;">
                               <div><el-input-number v-model="form.discount" :min="0" :max="100" precision=2 :disabled="disabled" controls-position="right"/></div>
                               <div>&nbsp;%</div>
                           </div>
                       </el-form-item>
                   </div>
                </el-col>
            </el-row>
            <el-row v-show="form.driverQuotationId">
                <el-col :span="12">
                    <el-form-item>
                        <el-button type="info" :disabled="true">承运商报价单</el-button>
                        <el-tag type="success" style="margin-left: 20px;cursor: pointer" v-if="driverQuotationInfo" @click="quotationDetail('driver')">{{driverQuotationInfo}}</el-tag>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="货物类型" prop="goodsTypeId">
                        <el-select :ref="el=>refMap.set('goodsTypeId',el)" v-model="form.goodsTypeId" multiple filterable clearable placeholder="请选择货物类型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'goodsTypeId')}">
                            <el-option v-for="item in comboSelect({comboId:'goodsTypeId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="结算周期" prop="jsType">
                        <el-select :ref="el=>refMap.set('jsType',el)" v-model="form.jsType" clearable placeholder="请选择结算周期" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'jsType')}">
                            <el-option v-for="item in comboSelect({comboId:'jsType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="发票类型" prop="invoiceType">
                        <el-select :ref="el=>refMap.set('invoiceType',el)" v-model="form.invoiceType" clearable placeholder="请选择发票类型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'invoiceType')}">
                            <el-option v-for="item in comboSelect({comboId:'invoiceType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="票点" prop="point">
                        <div style="display: flex;align-content: center;">
                            <div><el-input-number v-model="form.point" :min="0" :max="100" precision=2 :disabled="disabled" controls-position="right"/></div>
                            <div>&nbsp;%</div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="客户经理" prop="managerPersonId">
                        <el-select :ref="el=>refMap.set('managerPersonId',el)" v-model="form.managerPersonId" clearable placeholder="请选择客户经理" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'managerPersonId')}">
                            <el-option v-for="item in comboSelect({comboId:'managerPersonId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="调度专员" prop="schedulePersonId">
                        <el-select :ref="el=>refMap.set('schedulePersonId',el)" v-model="form.schedulePersonId" clearable placeholder="请选择调度专员" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'schedulePersonId')}">
                            <el-option v-for="item in comboSelect({comboId:'schedulePersonId'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="项目描述" prop="desc">
                        <div id="desc"></div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <el-dialog title="签约" v-model="uploadDialogVisible" width="50%" :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true">
            <el-form ref="signFormRef" :model="signForm" :rules="signFormRules" label-width="100px">
                <el-row v-if="form.saleBillId">
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="info" @click="chooseCustomerQuotation">选择客户报价单</el-button>
                            <el-tag type="success" style="margin-left: 20px;cursor: pointer" v-if="tjQuotationInfo" @click="quotationDetail">{{tjQuotationInfo}}</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="合同日期" prop="contractDate">
                            <el-date-picker v-model="signForm.contractDate" type="daterange" :unlink-panels="true" :shortcuts="utils.$$tools.defDateRange()" style="width: 100%"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上传合同">
                            <Upload v-bind="uploadParams" :ref="el=>refMap.set('saleBillContract',el)"></Upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="text-align: right;padding-right: 20px">
                        <el-form-item>
                            <el-button type="primary" @click="signSubmit">提交</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-collapse >
            <el-collapse-item title="合同信息" name="1" v-if="form.contractList && form.contractList.length>0">
                <div style="width: 50%;margin-left: 50px;">
                    合同开始时间&nbsp;:&nbsp;{{signForm.beganDate}} &nbsp;&nbsp;&nbsp;&nbsp;合同结束时间&nbsp;:&nbsp;{{signForm.endDate}}
                    <Upload v-bind="uploadParams" :ref="el=>refMap.set('saleBillContract',el)"></Upload>
                </div>
            </el-collapse-item>

<!--            <el-collapse-item title="项目车辆信息" name="2" v-if="form.saleBillCarVoList && form.saleBillCarVoList.length>0">-->
<!--                <el-table :data="form.saleBillCarVoList" style="width: 100%">-->
<!--                    <el-table-column prop="cooperationType" label="合作形式"/>-->
<!--                    <el-table-column prop="cx" label="车型"/>-->
<!--                    <el-table-column prop="carNum" label="车牌号"/>-->
<!--                    <el-table-column prop="zjcxType" label="准驾车型"/>-->
<!--                    <el-table-column prop="owner" label="所有人"/>-->
<!--                    <el-table-column prop="njTime" label="年检时间"/>-->
<!--                    <el-table-column prop="bxTime" label="保险时间"/>-->
<!--                    <el-table-column prop="driver" label="默认司机"/>-->
<!--                    <el-table-column prop="phone" label="注册手机"/>-->
<!--                </el-table>-->
<!--            </el-collapse-item>-->
        </el-collapse>
    </div>
</template>


<script>
    import SaleBillCardHelper from "./SaleBillCardHelper.js";
    export default SaleBillCardHelper;
</script>

<style scoped>
    .SaleBill{
        width: 100%;
    }
</style>
